/**
 * @module chartHelper
 */

/**
 * @typedef {object} Configuration
 * @property {string} type
 * @property {object} data
 * @property {object} options
 */

/** @typedef {import('../../../../node_modules/chart.js/types/index.esm').Chart} Chart */
/** @typedef {import('../../../../node_modules/chart.js/types/index.esm').ChartConfiguration} ChartConfiguration */

 /**
  * Creates and returns chartjs instance
  * @param {HTMLCanvasElement} canvas
  * @param {Configuration} configuration
  * @param {object} pluginOptions
  * @returns {Promise<Chart>}
  */
export async function createChart(canvas, configuration, pluginOptions={}) {
    if (!(canvas instanceof HTMLCanvasElement)) {
        throw new Error('Invalid canvas element');
    }

    if (pluginOptions.tooltipConfigName) {
        try {
            const {getConfig} = await import(`./chartTooltips/${pluginOptions.tooltipConfigName}.js`);
            const tooltip = getConfig(configuration.options?.plugin?.tooltip || {}, pluginOptions);

            if (configuration.options?.plugins?.tooltip) {
                configuration.options.plugins.tooltip = {
                    ...configuration.options.plugins.tooltip,
                    ...tooltip,
                };
            } else {
                configuration.options.plugins = {
                    ...configuration.options.plugins,
                    tooltip,
                };
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    }

    const {Chart, registerables} = await import('chart.js');
    Chart.register(...registerables);
    return new Chart(canvas, configuration);
}
