export function getDefaultLineConfig() {
    return {
        type: 'line',
        options: {
            // Update the chart measures on resize
            responsive: true,
            plugins: {
                legend: {
                    // Place the chart legend at the bottom
                    position: 'bottom',
                    labels: {
                        boxWidth: 20,
                        padding: 20,
                    },
                },
                tooltip: {
                    mode: 'index',
                    intersect: false,
                },
            },
            elements: {
                line: {
                    // Only draw straight lines (no bezier)
                    tension: 0,
                },
                point: {
                    radius: 5,
                    hoverRadius: 6,
                },
            },
            hover: {
                mode: 'index',
                intersect: false,
            },
            scales: {
                x: {
                    grid: {
                        // Hide X axis (vertical) grid lines
                        display: false,
                    },
                    display: true,
                    scaleLabel: {
                        display: false,
                    },
                },

                y: {
                    // Display Y Axes labels on the right
                    position: 'right',
                    grid: {
                        // Hide Y axes main line
                        drawBorder: false,
                    },
                    display: true,
                    scaleLabel: {
                        display: false,
                    },
                    // Always start at zero
                    beginAtZero: false,
                    ticks: {
                        /*
                         * Format Y Axis labels to display
                         * in thousands `{Number}k` when they
                         * are bigger than 1000, i.e. `10k`
                         */
                        callback: function (value) {
                            // If the tick value is lower than 1k,
                            // don’t change the format.
                            if (value < 1000) {
                                return value;
                            }

                            // Round to one decimal digit and display
                            // the formatted value in thousands.
                            value = value / 1000;
                            value = value.toFixed(1);
                            return `${value}k`;
                        },
                    },
                },
            },
        },
    };
}

export function getDefaultPieConfig() {
    return {
        type: 'doughnut',
        options: {
            responsive: true,
            plugins: {
                legend: {
                    position: 'bottom',
                    labels: {
                        boxWidth: 20,
                        padding: 20,
                    },
                },
            },
            hover: {
                mode: 'index',
                intersect: false,
            },
        },
    };
}
